<template>
  <div class="detail world">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="paddB10">
      <el-breadcrumb-item :to="{ path: '/enterprise/deposit' }" class="bs_text_oo">返回上一级<span> | </span>残保金审核
      </el-breadcrumb-item>
      <el-breadcrumb-item class="color66">{{checkById.checkYear}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="detail_content backgroundWhite">
      <div class="tip" v-if="checkById.auditStatus != '1'">
        <span class="review" v-if="checkById.auditStatus == '0'">待审核</span>
        <p class="err" v-if="checkById.auditStatus == '2'">审核未通过 <a href="javascript:void(0);" class="paddL10"
                                                   @click="dialogVisible = true">查看原因</a></p>
        <p v-if="checkById.auditStatus == '3'">未提交</p>

      </div>


      <section class="company_info relative">
        <div class="title">
          <el-divider><h1>单位信息</h1></el-divider>
        </div>
        <img src="~/static/lanzhou/enterprise/pass.png" alt="" class="pass" v-if="checkById.auditStatus == '1'">
        <div class="info">
          <p>
            <span>企业名称：</span>
            {{comInfo.companyName}}
          </p>
          <p class="flex">
            <span>营业执照：</span>
            <img :src="comInfo.licenseUrl" alt="" class="block_inline">
          </p>
          <p>
            <span>社会信用代码：</span>
            {{comInfo.socialCredit}}
          </p>
          <p>
            <span>法人代表：</span>
            {{comInfo.corporateRepresentative}}
          </p>
          <p>
            <span>注册地址：</span>
            {{comInfo.registerProvinced}}<span v-if="comInfo.registerCity">-{{comInfo.registerCity}}</span><span v-if="comInfo.registerThreecity">-{{comInfo.registerThreecity}}</span>
          </p>
          <p>
            <span>电话号码：</span>
            {{comInfo.telphone}}
          </p>
          <p>
            <span>单位性质：</span>
            {{comInfo.qyxz}}
          </p>
        </div>
      </section>
      <section class="job_info">
        <div class="title">
          <el-divider><h1>安置残疾人就业信息</h1></el-divider>
        </div>
        <el-table
          class="text_C"
          :data="tableData"
          stripe
          style="width: 100%">
          <el-table-column
            prop="name"
            align="center"
            label="姓名">
          </el-table-column>
          <el-table-column
            prop="sex"
            align="center"
            label="性别">
          </el-table-column>
          <el-table-column
            prop="idNumber"
            align="center"
            width="200"
            label="身份证号">
          </el-table-column>
          <el-table-column
            prop="disabledNumber"
            align="center"
            width="200"
            label="残疾证号">
          </el-table-column>
          <el-table-column
            align="center"
            prop="distabledType"
            label="残疾类型">
          </el-table-column>
          <el-table-column
            align="center"
            prop="distabledLevel"
            label="残疾等级">
          </el-table-column>
          <el-table-column
            align="center"
            width="180px"
            label="操作">
            <template slot-scope="scope">
              <button class="b_btn bs_text_oo" @click="disableid=scope.row.id;diabledlogVisible=true;editType='1'">
                详情
                <i class="line"></i>
              </button>

            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          class="work_pagination"
          :current-page.sync="form.pageNum"
          :page-size="form.pageSize"
          layout="prev, pager, next, jumper"
          prev-text="上一页"
          next-text="上一页"
          :total="total">
        </el-pagination>
      </section>
      <section class="tables">
        <div class="title">
          <el-divider><h1>全员工资表</h1></el-divider>
        </div>
        <el-row>
          <el-col :span="4">
             一月份：
          </el-col>
          <el-col :span="20">
        <ul class="images flex">
          <li v-for="item in january">
            <img :src="item" alt="">
          </li>
        </ul>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
             六月份：
          </el-col>
          <el-col :span="20">
        <ul class="images flex">
          <li v-for="item in june">
            <img :src="item" alt="">
          </li>
        </ul>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
             十二月份：
          </el-col>
          <el-col :span="20">
        <ul class="images flex">
          <li v-for="item in december">
            <img :src="item" alt="">
          </li>
        </ul>
          </el-col>
        </el-row>
      </section>
      <section class="tables paddB20">
        <div class="title">
          <el-divider><h1>就业统计表</h1></el-divider>
        </div>
        <ul class="images flex">
          <li v-for="item in jytjb">
            <img :src="item" alt="">
          </li>
        </ul>
      </section>
      <section class="tables paddB20">
        <div class="title">
          <el-divider><h1>社保缴费证明</h1></el-divider>
        </div>
        <ul class="images flex">
          <li v-for="item in socialList">
            <img :src="item" alt="">
          </li>
        </ul>
      </section>
      <section class="tables paddB20">
        <div class="title">
          <el-divider><h1>医保缴费证明</h1></el-divider>
        </div>
        <ul class="images flex">
          <li v-for="item in medicalList">
            <img :src="item" alt="">
          </li>
        </ul>
      </section>
    </div>
    <el-dialog
      title="原因"
      :visible.sync="dialogVisible"
      width="30%"
      >
      <div class="content">
     {{checkById.auditMsg}}
      </div>

    </el-dialog>
    <el-dialog
      title="残疾人就业信息"
      :visible.sync="diabledlogVisible"
      width="880px"
      :destroy-on-close="true"

      >
      <depositEdit  v-bind:id="disableid"  @updateDisable="updateDisable" :type="editType"></depositEdit>
    </el-dialog>

  </div>

</template>

<script>
  import depositEdit from '@/components/lanzhou-cdpe/depositEdit'

  export default {
    name: "detail",
    components: {
      depositEdit,
    },
    head() {
      return {
        title: '残保金审核',
        meta: []
      }
    },
    data() {
      return {
        status: 0,
        checkById:{},
        editType:'1',
        disableid:'',
        dialogVisible:false,
        diabledlogVisible:false,
        form:{
          id:'',
          pageNum:1,
          pageSize:10,
        },
        comInfo:{},
        total:0,
        january:[],
        june:[],
        december:[],
        socialList: [],
        medicalList: [],
        jytjb:[],
        tableData: [

        ],
      }
    },
    methods:{
      getAlldisable: function () {
        this.$api.lanzhouGetDisabledsApi(this.form).then(response => {
          this.tableData = response.data.data.disabledPrimumChecks.list;
          this.total = response.data.data.disabledPrimumChecks.total;
        })
      },
      handleCurrentChange(){
        this.pageNum+1;
        this.getAlldisable();
      },
      updateDisable:function () {
        this.dialogVisible=false;
      },
      handleClose(){
        this.dialogVisible = false;
      },
      //从新页面打开
      goRouter(id) {
        const {href} = this.$router.resolve({
          path: '/deposit/detail/',
          query: {id: id}
        })
        window.open(href)
      }
    },
    created(){
     this.form.id=this.$route.query.id;
     let ruleForm=this.form;
      this.$api.lanzhouGetComCheckApi(ruleForm).then(response => {
        response = response.data;
        if (response.success) {
         this.comInfo=response.data.comInfo;
         this.checkById=response.data.checkById;
         this.january=response.data.fullSalary.january.split(";");
         this.june=response.data.fullSalary.june.split(";");
         this.december=response.data.fullSalary.december.split(";");
         this.jytjb=response.data.allEmployStatis.statistics.split(";");

         this.socialList=response.data.socialList;
         this.medicalList=response.data.medicalList;
         this.getAlldisable();

        } else {
          this.$message.error(response.data.msg);
        }

      })
    }
  }
</script>

<style scoped lang="less">
@import "~assets/lanzhou/enterprise.less";
@import "~assets/lanzhou/deposit.less";
</style>
